import React from "react";

export default () => puzzleIcon;

const puzzleIcon = (
  <svg
    viewBox="0 0 512 512"
    width="100%"
    height="100%"
    preserveAspectRatio="xMidYMid meet"
  >
    <path d="m0 0v512h512v-512zm482 241h-74.996094v60.503906c0 16.820313-13.683594 30.503906-30.503906 30.503906s-30.503906-13.683593-30.503906-30.503906v-60.503906h-74.996094v-44.996094h30.503906c33.363282 0 60.503906-27.140625 60.503906-60.503906s-27.140624-60.503906-60.503906-60.503906h-30.503906v-44.996094h211zm-241-211v74.996094h60.503906c16.820313 0 30.503906 13.683594 30.503906 30.503906s-13.683593 30.503906-30.503906 30.503906h-60.503906v74.996094h-44.996094v-30.503906c0-33.363282-27.140625-60.503906-60.503906-60.503906s-60.503906 27.140624-60.503906 60.503906v30.503906h-44.996094v-211zm-211 241h74.996094v-60.503906c0-16.820313 13.683594-30.503906 30.503906-30.503906s30.503906 13.683593 30.503906 30.503906v60.503906h74.996094v44.996094h-30.503906c-33.363282 0-60.503906 27.140625-60.503906 60.503906s27.140624 60.503906 60.503906 60.503906h30.503906v44.996094h-211zm241 211v-74.996094h-60.503906c-16.820313 0-30.503906-13.683594-30.503906-30.503906s13.683593-30.503906 30.503906-30.503906h60.503906v-74.996094h44.996094v30.503906c0 33.363282 27.140625 60.503906 60.503906 60.503906s60.503906-27.140624 60.503906-60.503906v-30.503906h44.996094v211zm0 0" />
  </svg>
);
