import React from "react";
import { bigButton } from "../css";
import useYScrollHidden from "../common/useYScrollHidden";

export default function Welcome({ onHide: hidden }: { onHide: () => void }) {
  useYScrollHidden();
  return (
    <div className="bg-near-white flex vh-100 w-100 items-center justify-center fixed left-0 top-0 z-999">
      <div className="ph2 tc tj-ns mw6">
        <h3 className="f2 normal pa0 ma0 lh-copy tc">
          Hi, I'm Christian, a software engineer.
        </h3>
        <p className="f3 lh-copy mb0 tc">
          <span className="bg-light-yellow ph2">Web frontend</span> with
          JavaScript, TypeScript, React, Svelte, HTML, CSS.{" "}
          <span className="bg-light-yellow ph2">Backend</span> with .Net/C#,
          Node.js, (MS-)SQL. I fill your resource gaps, take on whole projects
          and create prototypes.
        </p>
        <div className="pt5 tc">
          <a
            href="#filterDiv"
            className={bigButton}
            onClick={(e) => {
              hidden();
              e.preventDefault();
            }}
          >
            Find out how I can help you
          </a>
        </div>
      </div>
    </div>
  );
}
