import React from "react";

export default () => certificateIcon;

const certificateIcon = (
  <svg
    version="1.1"
    viewBox="0 0 241.933 241.933"
    width="100%"
    height="100%"
    preserveAspectRatio="xMidYMid meet"
  >
    <g>
      <path
        d="M213.937,85.577l-18.398-19.016l3.706-26.192c0.567-4.006-2.145-7.74-6.131-8.438l-26.058-4.568L154.652,3.986
  c-1.896-3.573-6.287-4.999-9.919-3.222L120.969,12.38L97.199,0.764c-3.635-1.777-8.022-0.35-9.919,3.224L74.881,27.363
  L48.82,31.931c-3.985,0.698-6.698,4.432-6.131,8.438l3.705,26.192L27.996,85.577c-2.813,2.908-2.813,7.522,0.001,10.43
  l18.397,19.014l-3.705,26.206c-0.566,4.005,2.146,7.739,6.131,8.437l20.857,3.656v81.113c0,2.599,1.346,5.013,3.557,6.379
  c1.204,0.745,2.572,1.121,3.943,1.121c1.146,0,2.295-0.262,3.354-0.792l40.439-20.212l40.433,20.212
  c2.324,1.162,5.086,1.038,7.297-0.329c2.211-1.367,3.557-3.781,3.557-6.38v-81.111l20.858-3.658
  c3.985-0.699,6.697-4.432,6.131-8.438l-3.706-26.206l18.397-19.013C216.749,93.1,216.749,88.485,213.937,85.577z M124.323,205.835
  c-2.11-1.055-4.595-1.056-6.707,0l-32.939,16.464v-49.605l2.604,4.909c1.896,3.575,6.288,5,9.92,3.222l23.769-11.624l23.762,11.624
  c1.06,0.518,2.184,0.764,3.291,0.764c2.69,0,5.286-1.454,6.63-3.985l2.604-4.905v49.6L124.323,205.835z M182.209,107.225
  c-1.609,1.663-2.36,3.975-2.036,6.266l3.203,22.651l-22.521,3.949c-2.278,0.399-4.245,1.828-5.33,3.871l-10.721,20.2
  l-20.539-10.047c-1.04-0.508-2.168-0.763-3.296-0.763c-1.128,0-2.256,0.254-3.295,0.763L97.13,164.162l-10.717-20.199
  c-1.084-2.044-3.051-3.473-5.33-3.872l-22.526-3.949l3.202-22.652c0.324-2.29-0.427-4.603-2.036-6.265L43.822,90.791l15.902-16.436
  c1.608-1.663,2.359-3.975,2.035-6.265l-3.202-22.637l22.526-3.949c2.279-0.399,4.246-1.829,5.331-3.873l10.717-20.205l20.545,10.04
  c2.078,1.016,4.509,1.016,6.587,0l20.539-10.04l10.723,20.206c1.084,2.043,3.051,3.472,5.33,3.872l22.521,3.948l-3.203,22.637
  c-0.324,2.291,0.427,4.603,2.035,6.266l15.902,16.436L182.209,107.225z"
      />
      <path
        d="M120.969,39.507c-28.283,0-51.293,23.006-51.293,51.285c0,28.285,23.01,51.296,51.293,51.296
  c28.279,0,51.286-23.011,51.286-51.296C172.255,62.513,149.248,39.507,120.969,39.507z M120.969,127.088
  c-20.012,0-36.293-16.282-36.293-36.296c0-20.008,16.281-36.285,36.293-36.285c20.008,0,36.286,16.277,36.286,36.285
  C157.255,110.806,140.977,127.088,120.969,127.088z"
      />
      <path
        d="M136.258,71.765l-22.155,22.15l-8.431-8.421c-2.93-2.928-7.679-2.925-10.607,0.006c-2.927,2.931-2.924,7.679,0.007,10.606
  l13.733,13.718c1.464,1.463,3.382,2.194,5.3,2.194c1.919,0,3.839-0.732,5.303-2.196l27.455-27.449
  c2.93-2.928,2.93-7.677,0.001-10.607C143.936,68.837,139.187,68.836,136.258,71.765z"
      />
    </g>
  </svg>
);
