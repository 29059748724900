import React from "react";

export default () => codeIcon;

const codeIcon = (
  <svg
    viewBox="0 -20 512 512"
    width="100%"
    height="100%"
    preserveAspectRatio="xMidYMid meet"
  >
    <path d="m346.617188 197h-41.558594l-11.273438 40h-40.441406l11.273438-40h-41.558594l-11.273438 40h-41.25l-11.273437 40h41.25l-10.988281 39h-41.25l-11.273438 40h41.25l-11.273438 40h41.558594l11.273438-40h40.441406l-11.273438 40h41.558594l11.273438-40h41l11.273437-40h-41l10.988281-39h41l11.273438-40h-41zm-75.09375 119h-40.441407l10.988281-39h40.445313zm180.476562-316h-392c-33.085938 0-60 26.914062-60 60v352c0 33.085938 26.914062 60 60 60h392c33.085938 0 60-26.914062 60-60v-352c0-33.085938-26.914062-60-60-60zm-392 40h392c11.027344 0 20 8.972656 20 20v60h-432v-60c0-11.027344 8.972656-20 20-20zm392 392h-392c-11.027344 0-20-8.972656-20-20v-252h432v252c0 11.027344-8.972656 20-20 20zm-382-352c0-11.046875 8.953125-20 20-20s20 8.953125 20 20-8.953125 20-20 20-20-8.953125-20-20zm70 0c0-11.046875 8.953125-20 20-20s20 8.953125 20 20-8.953125 20-20 20-20-8.953125-20-20zm0 0" />
  </svg>
);
