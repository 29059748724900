import React, { PropsWithChildren } from "react";
import "./RolesPage.css";

export default function RolesPage(props: RolesPageProps) {
  const filterDiv = React.createRef() as any;
  return (
    <div className="pa2" ref={filterDiv} id="filterDiv">
      <h1 className="tc">What are you looking for?</h1>
      <div className="container">
        <Role title="Web Developer" />
        <Role title=".NET Developer" />
        <Role title="Data Specialist" />
        {/* <Role title="Problem Solver" /> */}
      </div>
      {/* <Filters onChange={props.onFiltersChange} {...props} /> */}
    </div>
  );
}

interface RolesPageProps {}

function Role(props: { title: string }) {
  const hue1 = 50;
  const hue2 = 240;
  return (
    <div className="role">
      <h2 className="tc">{props.title}</h2>
      <div>
        <div className="relative">
          <div className="tc lh-copy o-30">
            <Terms
              hue={hue1}
              terms={[
                "Finance",
                "Retail",
                "Exhibitions",
                "Online Registrations",
                "MiFID",
                "Migration",
                "Sales",
                "Reporting",
              ]}
            />
          </div>
          <div className="absolute left-0 top-0 w-100 h-100 flex flex-column justify-center">
            <button
              className="pa2 bn db link white br2 shadow-3 w4 center nowrap"
              style={{ backgroundColor: `hsl(${hue1}, 100%, 40%)` }}
            >
              What I did
            </button>
          </div>
        </div>
        <div className="relative">
          <div className="tc lh-copy o-30">
            <Terms
              hue={hue2}
              terms={[
                "React",
                "CSS",
                "HTML",
                "JavaScript",
                "TypeScript",
                "Tachyons",
                "Material-Ui",
                "Nodejs",
                "ASP.NET Core",
              ]}
            />
          </div>
          <div className="absolute left-0 top-0 w-100 h-100 flex flex-column justify-center">
            <button
              className="pa2 bn db link white br2 shadow-3 w4 center nowrap"
              style={{ backgroundColor: `hsl(${hue2}, 100%, 40%)` }}
            >
              What I used
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Terms(props: { hue: number; terms: string[] }) {
  return (
    <>
      {props.terms.map((t) => (
        <Term key={t} hue={props.hue}>
          {t}
        </Term>
      ))}
    </>
  );
}

function Term(props: PropsWithChildren<{ hue: number }>) {
  return (
    <span
      className="dib pa2 term"
      style={{
        color: `hsl(${props.hue}, 100% ,25%)`,
      }}
    >
      {props.children}
    </span>
  );
}

function rangify(
  value: number,
  valueRangeStart: number,
  valueRangeEnd: number,
  rangeStart: number,
  rangeEnd: number
) {
  return (
    (value / (valueRangeEnd - valueRangeStart)) * (rangeEnd - rangeStart) +
    rangeStart
  );
}

function random(start: number, end: number) {
  return rangify(Math.random(), 0, 1, start, end);
}
