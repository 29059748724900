import React from "react";

export default () => manWithCompanyIcon;

const manWithCompanyIcon = (
  <svg
    version="1.1"
    width="100%"
    height="100%"
    viewBox="0 0 572.426 572.426"
    preserveAspectRatio="xMidYMid meet"
  >
    <g>
      <g>
        <path
          d="M368.8,184.672c27.518,0,49.906-22.389,49.906-49.907c0-27.52-22.388-49.909-49.906-49.909
			c-27.512,0-49.895,22.39-49.895,49.909C318.906,162.283,341.288,184.672,368.8,184.672z"
        />
        <path
          d="M569.241,309.187l-35.982-35.982c-2.054-2.054-4.792-3.185-7.712-3.185c-2.919,0-5.658,1.131-7.712,3.185l-10.524,10.524
			c-0.432-0.505-0.878-0.999-1.352-1.474c-4.825-4.826-11.334-7.484-18.327-7.485l-17.057-23.258
			c-4.539-6.191-12.204-14.811-17.831-20.052l-35.153-32.736c-2.522-2.349-7.544-5.664-13.351-5.664c0,0-61.263,0-61.593,0
			c-8.06,0-15.304,4.477-18.905,11.683l-25.67,51.361c-1.891,3.783-6.592,10.518-9.491,13.597l-18.164,19.297
			c-3.867,4.108-5.902,9.476-5.731,15.114c0.17,5.639,2.527,10.873,6.634,14.738c3.932,3.702,9.071,5.741,14.472,5.741
			c5.801,0,11.407-2.421,15.381-6.642l18.163-19.297c5.697-6.052,12.798-16.226,16.517-23.665l3.819-7.642
			c0,0,5.346,30.242,6.863,40.329c0.076,0.502,0.1,1.112,0.088,1.25l-5.925,66.5c-0.64,7.188-0.944,18.461-0.692,25.664
			l2.367,67.785c0.421,12.051,10.19,21.49,22.241,21.49c0.26,0,0.523-0.004,0.79-0.013c12.27-0.431,21.905-10.762,21.477-23.032
			l-2.367-67.785c-0.188-5.38,0.066-14.799,0.544-20.156l3.972-44.581h8.382l12.227,51.081c1.786,7.461,5.444,18.385,8.509,25.41
			l25.759,59.053c3.544,8.121,11.56,13.369,20.421,13.369c0.202,0,0.404-0.003,0.606-0.009c2.856-0.078,5.644-0.701,8.286-1.853
			c5.452-2.378,9.651-6.737,11.824-12.273c2.173-5.537,2.061-11.588-0.317-17.04l-25.76-59.055
			c-2.026-4.645-4.838-13.041-6.018-17.969L430.518,269.21c2.299,2.586,4.507,5.248,5.997,7.279l17.14,23.374
			c1.488,2.028,3.316,3.751,5.374,5.11c0.357,6.4,2.947,12.285,7.439,16.774c0.474,0.474,0.968,0.92,1.472,1.351l-10.524,10.524
			c-2.054,2.054-3.185,4.792-3.185,7.712c0,2.92,1.131,5.658,3.185,7.712l35.983,35.984c2.054,2.054,4.793,3.185,7.712,3.185
			s5.658-1.131,7.712-3.185l60.418-60.42c2.054-2.054,3.185-4.792,3.185-7.712C572.426,313.979,571.294,311.241,569.241,309.187z
			 M475.629,312.585c-1.211-1.21-2.135-2.642-2.759-4.216c3.719-0.38,7.247-1.724,10.304-3.967
			c4.549-3.336,7.527-8.243,8.384-13.819c0.113-0.735,0.182-1.47,0.219-2.203c1.895,0.609,3.613,1.631,5.02,3.038
			c0.478,0.478,0.897,0.999,1.286,1.539l-20.915,20.914C476.626,313.482,476.106,313.062,475.629,312.585z"
        />
      </g>
      <path
        d="M255.161,359.058h-11.2V122.663c0-5.959-4.848-10.807-10.807-10.807h-29.355V92.868c0-5.959-4.848-10.807-10.807-10.807
		H72.507c-5.959,0-10.807,4.848-10.807,10.807v18.988H32.345c-5.959,0-10.807,4.848-10.807,10.807v236.395h-11.2
		c-5.7,0-10.338,4.638-10.338,10.338v35.449c0,5.7,4.638,10.338,10.338,10.338h244.823c5.7,0,10.338-4.638,10.338-10.338v-35.449
		C265.499,363.695,260.861,359.058,255.161,359.058z M91.549,314.547c0,1.65-1.35,3-3,3h-39.25c-1.65,0-3-1.35-3-3v-39.25
		c0-1.65,1.35-3,3-3h39.25c1.65,0,3,1.35,3,3V314.547z M91.549,251.881c0,1.65-1.35,3-3,3h-39.25c-1.65,0-3-1.35-3-3v-39.25
		c0-1.65,1.35-3,3-3h39.25c1.65,0,3,1.35,3,3V251.881z M91.549,188.548c0,1.65-1.35,3-3,3h-39.25c-1.65,0-3-1.35-3-3v-39.25
		c0-1.65,1.35-3,3-3h39.25c1.65,0,3,1.35,3,3V188.548z M155.374,367.548c0,1.65-1.35,3-3,3h-39.25c-1.65,0-3-1.35-3-3v-92.251
		c0-1.65,1.35-3,3-3h39.25c1.65,0,3,1.35,3,3V367.548z M155.374,251.881c0,1.65-1.35,3-3,3h-39.25c-1.65,0-3-1.35-3-3v-39.25
		c0-1.65,1.35-3,3-3h39.25c1.65,0,3,1.35,3,3V251.881z M155.374,188.548c0,1.65-1.35,3-3,3h-39.25c-1.65,0-3-1.35-3-3v-39.25
		c0-1.65,1.35-3,3-3h39.25c1.65,0,3,1.35,3,3V188.548z M219.374,314.547c0,1.65-1.35,3-3,3h-39.25c-1.65,0-3-1.35-3-3v-39.25
		c0-1.65,1.35-3,3-3h39.25c1.65,0,3,1.35,3,3V314.547z M219.374,251.881c0,1.65-1.35,3-3,3h-39.25c-1.65,0-3-1.35-3-3v-39.25
		c0-1.65,1.35-3,3-3h39.25c1.65,0,3,1.35,3,3V251.881z M219.374,188.548c0,1.65-1.35,3-3,3h-39.25c-1.65,0-3-1.35-3-3v-39.25
		c0-1.65,1.35-3,3-3h39.25c1.65,0,3,1.35,3,3V188.548z"
      />
    </g>
  </svg>
);
